.CheckBoxContainer {
  display: flex;
}

.CheckBox + * {
  margin-left: 6px;
}

.CheckBox {
  height: var(--toy-box-size-200);
  width: var(--toy-box-size-200);
  position: relative;
  background-color: white;
  border: var(--toy-box-color-gray-600) 2px solid;
}

.CheckBox > input {
  height: var(--toy-box-size-200);
  width: var(--toy-box-size-200);
  opacity: 0;

  /* Avoid vertical scrolling */
  display: flex;
  width: 100%;
  height: 100%;
}

.disabled {
  background-color: var(--toy-box-color-gray-200);
  border: var(--toy-box-color-gray-500) 2px solid;
}

.checkMark {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--toy-box-color-primary-300);
  opacity: 0;
  transition: opacity 100ms linear;
}

.secondary .checkMark {
  background-color: var(--toy-box-color-secondary-300);
}

.disabled .checkMark {
  background-color: var(--toy-box-color-primary-200);
}

.secondary.disabled .checkMark {
  background-color: var(--toy-box-color-secondary-200);
}

.checkMarkInner {
  position: absolute;
  width: calc(var(--toy-box-size-050) * 1.3);
  height: calc(var(--toy-box-size-100) * 1.3);
  left: calc(var(--toy-box-size-100) / 2);
  top: 0px;
  border: 2px solid white;
  border-left: none;
  border-top: none;
  transform: rotate(45deg);
}

input:checked + .checkMark {
  opacity: 1;
}
