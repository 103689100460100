.RadioContainer {
  display: flex;
  align-items: center;
}

.Radio {
  height: var(--toy-box-size-200);
  width: var(--toy-box-size-200);
  border-radius: 100%;
  position: relative;
  background-color: white;
  border: var(--toy-box-color-gray-600) 2px solid;
}

.Radio + * {
  margin-left: 6px;
}

.Radio > input {
  height: var(--toy-box-size-200);
  width: var(--toy-box-size-200);
  opacity: 0;

  /* Avoid vertical scrolling */
  display: flex;
  width: 100%;
  height: 100%;
}

.disabled {
  background-color: var(--toy-box-color-gray-200);
  border: var(--toy-box-color-gray-500) 2px solid;
}

.checkMark {
  border-radius: 100%;
  pointer-events: none;
  position: absolute;
  top: 2px;
  left: 2px;
  height: calc(var(--toy-box-size-200) - 8px);
  width: calc(var(--toy-box-size-200) - 8px);
  background-color: var(--toy-box-color-primary-300);
  opacity: 0;
  transition: opacity 100ms linear;
}

.secondary .checkMark {
  background-color: var(--toy-box-color-secondary-300);
}

.disabled .checkMark {
  background-color: var(--toy-box-color-primary-300);
}

.secondary.disabled .checkMark {
  background-color: var(--toy-box-color-secondary-300);
}

input:checked + .checkMark {
  opacity: 1;
}
