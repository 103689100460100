.Tabs {
  height: 60px; /* Prevent Tabs fro mscrolling inside Rows */
}

.Tabs > * {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
