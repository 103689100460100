.overlay {
  position: fixed;
  transition-duration: 150ms;
  transition-property: transform;
  overflow: auto;
}

.m {
  /* Empty m size */
}

.center {
  width: min(640px, 100% - 40px);
  max-height: 100% - 40px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-100%);
}

.center.xl {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.center.openVisible {
  transform: translateX(-50%) translateY(-50%);
}

.left,
.right {
  width: min(440px, 100% - 20px);
  top: 0;
  bottom: 0;
}

.left > *,
.right > *,
.top > *,
.bottom > * {
  height: 100%;
}

.left {
  left: 0;
  transform: translateX(-100%);
}

.right {
  right: 0;
  transform: translateX(100%);
}

.left.xl,
.right.xl {
  width: calc(100% - 20px);
}

.left.l,
.right.l {
  width: min(calc(100% - 20px), 640px);
}

.left.openVisible,
.right.openVisible {
  transform: translateX(0);
}

.top,
.bottom {
  height: min(440px, 100% - 20px);
  left: 0;
  right: 0;
}

.top.xl,
.bottom.xl {
  height: calc(100% - 20px);
}

.top.l,
.bottom.l {
  height: min(800px, calc(100% - 20px));
}

.top {
  top: 0;
  transform: translateY(-100%);
}

.bottom {
  bottom: 0;
  transform: translateY(100%);
}

.top.openVisible,
.bottom.openVisible {
  transform: translateY(0);
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 150ms;
  transition-property: opacity;
  opacity: 0;
}

.backdrop.openVisible {
  opacity: 1;
}
