.Select {
  height: var(--toy-box-size-200);
  width: 100%;
  background-color: var(--toy-box-color-gray-100);
  border-style: solid;
  border-width: 1px;
  outline: none;
  font-family: Proxima Nova Rg;
}

.selectDisabled {
  color: gray;
}

.optionDisabled {
  color: gray;
}

.option {
  color: black;
}
