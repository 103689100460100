.Table {
  width: 100%;
}

.Table.striped tbody tr:nth-child(2n + 1) {
  background-color: rgb(206, 206, 206);
}

.Table.highlight-on-hover tbody tr:hover {
  background-color: rgb(205, 211, 209);
}
