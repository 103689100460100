@keyframes loader-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.Loader, .LoaderProgress {
  overflow: hidden;
}

.LoaderProgress {
  background-color: var(--toy-box-color-gray-500);
  border-radius: 4px;
}

.LoaderInnerSpinner {
  border-radius: 100%;
  border: 2px solid;
  border-color: var(--toy-box-color-gray-700);
  border-bottom-color: transparent;
  animation: 750ms loader-rotation linear;
  animation-iteration-count: infinite;
}

.LoaderInnerSpinner.sm {
  height: var(--toy-box-size-100);
  width: var(--toy-box-size-100);
}

.LoaderInnerSpinner.md {
  height: var(--toy-box-size-200);
  width: var(--toy-box-size-200);
}

.LoaderInnerSpinner.lg {
  height: var(--toy-box-size-400);
  width: var(--toy-box-size-400);
}

.LoaderInnerSpinner.primary {
  border-color: var(--toy-box-color-primary-500);
  border-bottom-color: transparent;
}

.LoaderInnerSpinner.secondary {
  border-color: var(--toy-box-color-secondary-500);
  border-bottom-color: transparent;
}

.LoaderInnerProgress {
  background-color: var(--toy-box-color-primary-500);
  height: var(--toy-box-size-100);
  transition: width 0.1s;
}

.LoaderInnerProgress.sm {
  height: var(--toy-box-size-050);
}

.LoaderInnerProgress.lg {
  height: var(--toy-box-size-200);
}

.LoaderInnerProgress.secondary {
  background-color: var(--toy-box-color-secondary-500);
}
