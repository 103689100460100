.App {
  display: flex;
  height: 100vh; /* Fallback for electron that does not support dvh */
  height: 100dvh;
}

.BtnEdit {
  position: fixed;
  bottom: 2px;
  right: 2px;
}

.AppRendererContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 0;
}

.AppRendererContainer > * {
  height: 100%;
}
