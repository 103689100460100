.Text {
  position: relative;
}

.buttonCopy {
  z-index: 1;
  position: absolute;
  left: 2px;
  top: 2px;
  opacity: 0;
  transition: linear 100ms opacity;
}

.Text:hover .buttonCopy {
  opacity: 1;
}

.Text pre {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  max-height: 200px;
  overflow: hidden;
}
