.Button {
  font-family: Proxima Nova Rg;
  font-weight: 800;
  min-height: var(--toy-box-size-200);
  border-style: solid;
  border-width: 1px;
  white-space: nowrap;
}

.Button {
  color: var(--toy-box-color-primary-100);
  background-color: var(--toy-box-color-primary-400);
  border-color: var(--toy-box-color-primary-700);
}

.Button.secondary {
  color: var(--toy-box-color-secondary-100);
  background-color: var(--toy-box-color-secondary-400);
  border-color: var(--toy-box-color-secondary-700);
}

.Button:active:enabled {
  background-color: var(--toy-box-color-primary-300);
}

.Button.secondary:active:enabled {
  background-color: var(--toy-box-color-secondary-300);
}

.Button:disabled {
  border-color: var(--toy-box-color-primary-600);
  background-color: var(--toy-box-color-primary-200);
}

.Button.secondary:disabled {
  border-color: var(--toy-box-color-secondary-600);
  background-color: var(--toy-box-color-secondary-200);
}

.Button.outline {
  background-color: transparent;
  color: var(--toy-box-color-primary-500);
}

.Button.outline.secondary {
  color: var(--toy-box-color-secondary-500);
}

.Button.outline:disabled {
  color: var(--toy-box-color-primary-200);
  background-color: var(--toy-box-color-gray-300);
}

.Button.outline.secondary:disabled {
  color: var(--toy-box-color-secondary-200);
}

.Button.outline:active:enabled {
  background-color: var(--toy-box-color-gray-300);
}
