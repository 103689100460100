.SwitchOuterContainer {
  display: flex;
  align-items: center;
}

.SwitchContainer {
  position: relative;
  height: var(--toy-box-size-200);
}

.SwitchContainer + * {
  margin-left: 6px;
}

.Switch,
.background {
  width: var(--toy-box-size-400);
  height: var(--toy-box-size-200);
}

.Switch {
  opacity: 0;
  /* Avoid vertical scrolling */
  display: flex;
}

.background {
  pointer-events: none;
  top: 0;
  left: 0;
  background-color: var(--toy-box-color-gray-600);
  transition: background-color 100ms linear;
  border-radius: var(--toy-box-size-100);
}

.backgroundDisabled {
  background-color: var(--toy-box-color-gray-500);
}

.switch {
  display: block;
  height: calc(var(--toy-box-size-200) - 4px);
  width: calc(var(--toy-box-size-200) - 4px);
  background-color: white;
  top: 2px;
  left: 2px;
  pointer-events: none;
  transition: transform 100ms linear;
  border-radius: 100%;
}

.switchDisabled {
  background-color: var(--toy-box-color-gray-200);
}

.background,
.switch {
  position: absolute;
}

.Switch:checked + .background {
  background-color: var(--toy-box-color-primary-300);
}

.secondary > .Switch:checked + .background {
  background-color: var(--toy-box-color-secondary-300);
}

.Switch:checked + .backgroundDisabled {
  background-color: var(--toy-box-color-primary-200);
}

.secondary > .Switch:checked + .backgroundDisabled {
  background-color: var(--toy-box-color-secondary-200);
}

.Switch:checked + .background + .switch {
  transform: translateX(var(--toy-box-size-200));
}
