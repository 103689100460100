div.CustomStyles {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}

div.WindowCustomStyles {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.drawer {
  height: 100%;
}

.Body {
  overflow-y: auto;
}
