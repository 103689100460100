.InputFile {
  height: var(--toy-box-size-200);
  background-color: var(--toy-box-color-gray-100);
  border-style: solid;
  border-width: 1px;
  outline: none;
  font-family: Proxima Nova Rg;

  transition-property: background-color;
  transition-duration: 250ms;
}

.InputFile:focus {
  background-color: white;
  border-color: var(--toy-box-color-gray-600);
}

.InputFile:hover {
  background-color: white;
}
