.WindowFrame {
  background-color: var(--toy-box-color-gray-400);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.WindowFrame.center.noBackdrop {
  box-shadow: 0px 0px 5px grey;
  margin: 5px; /* Add a margin to show the box-shadow */
  max-height: calc(100% - 10px);
}

.Header {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  border-bottom: solid 2px var(--toy-box-color-primary-500);
}

.Body {
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  overflow: auto;
}

.Footer {
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
}
