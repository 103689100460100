.tabs {
  display: flex;
  flex-direction: row;
  height: var(--toy-box-size-400);
  padding-bottom: var(--toy-box-size-100);
}

.tab {
  transition-property: border-width border-color color;
  transition-duration: 100ms;
  transition-timing-function: ease;
}

.tab {
  padding: var(--toy-box-size-050) var(--toy-box-size-100);
  cursor: pointer;
  border-bottom: solid 1px var(--toy-box-color-gray-700);
}

.tabSpacer {
  flex-grow: 1;
  padding: var(--toy-box-size-050) var(--toy-box-size-100);
  border-bottom: solid 1px var(--toy-box-color-gray-700);
}

.selected {
  color: var(--toy-box-color-primary-700);
  border-bottom: solid 3px var(--toy-box-color-primary-500);
}
